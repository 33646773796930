import { template as template_b74b87761e36440a94cfd0eb26c43d3e } from "@ember/template-compiler";
const SidebarSectionMessage = template_b74b87761e36440a94cfd0eb26c43d3e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
