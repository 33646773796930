import { template as template_469b45c1d0204abd80b177864bbca2e4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_469b45c1d0204abd80b177864bbca2e4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
