import { template as template_df41b0718ba44b889cb2d1801e32bedf } from "@ember/template-compiler";
const FKText = template_df41b0718ba44b889cb2d1801e32bedf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
